
@import "../../node_modules/slick-carousel/slick/slick.css";
@import "../../node_modules/slick-carousel/slick/slick-theme.css";

/** in your main global CSS or style tag */
/** Use your browser's css inspector and inspect the HTML element to see other variables to override. */
:root {
  --mdc-theme-primary: #916c00;
  --mdc-theme-secondary: #F7D08A;
}

html {
  font-size: 14px;
}

*:focus {outline:none !important}

a:focus,
button:focus,
input:focus,
textarea:focus {
  outline: none;
}

.input-secondary { 
  text-align: left;
  font-size: 1.2em;
  font-weight: 300;
  border: 1px solid #cccccc;
}

a {
  color: rgb(36, 36, 36);
}

a:hover {
  color: rgb(36, 36, 36);
}

@media (max-width: 1024px) {

  .input-secondary { 
    text-align: center;
    font-size: 1.5em;
    border: none ;
    font-weight: 100;
    background: transparent;
  }
}


.hl-gold{
  background: linear-gradient(180deg,rgba(255,255,255,0) 55%, var(--mdc-theme-secondary) 80%);
}

.hl-silver{
  background: linear-gradient(180deg,rgba(255,255,255,0) 55%, #e4e4e4 80%);
}


.spinner {
  margin: 30px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: rgb(199, 199, 199);

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.spinner-btn {
  text-align: center;
  display: inline-block;
}

.spinner-btn-dark {
  text-align: center;
  display: inline-block;
}

.spinner-btn  > div {
  width: 5px;
  height: 5px;
  background-color: rgb(255, 255, 255);

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-btn .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner-btn .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.spinner-btn-dark  > div {
  width: 5px;
  height: 5px;
  background-color: rgb(207, 207, 207);

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-btn-dark .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner-btn-dark .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none !important;
  color: #495057;
}

pre {
  background-color: #f8f8f8;
  border: 1px solid #ececec !important;
  font-size: 13px;
  line-height: 19px;
  overflow: auto;
  padding: 6px 10px;
  border-radius: 3px;
}

.promo{
  border: 0;
  outline: 0;
  background: transparent;
  border-bottom: 1px solid lightgrey;
  font-size: 12px;
}

.badge-api-key{
  background-color: #F7D08A;
  font-weight: 500;
  padding: .50em .4em;
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

.dashed {
  border-bottom: 1px dashed #7d7d7d;
}

.spinner-button {
  margin: 0px auto 0px;
  width: 35px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
}

.spinner-button > div {
  width: 8px;
  height: 8px;
  background-color: #ffffff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-button .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner-button .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.spinner-button-white {
  margin: 0px auto 0px;
  width: 35px;
  text-align: center;
  vertical-align: middle;
  display: inline-block;
}

.spinner-button-white > div {
  width: 8px;
  height: 8px;
  background-color: #ffffff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner-button-white .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner-button-white .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

.badge-plan{
  border-radius: 5px;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 6px;
  padding-right: 10px;
  font-weight: 500;
  font-size: 11px;
  color: black;
}

.free{
  background-color: rgb(185, 24, 24);
  color: white;
}

.unlimited{
  background-color: white;
  color: gray;
  font-size: 12px;
}

.btn-log-out{
  text-transform: capitalize;
}

.navigation{
  background-color: #f5f5f5;
}

.image-shadow{
  -webkit-filter: drop-shadow(0px 0px 20px rgb(131, 131, 131));
  filter: drop-shadow(0px 0px 20px rgb(124, 124, 124));
}

@media only screen and (max-width: 768px) {
  .hide-small { display:none !important; }

}

@media only screen and (min-width: 768px) {
  .hide-large { display:none !important; }
}

.badge-get{
  padding-top: 4px;
  background-color: #F7D08A;
}

.pulse {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #f7cf89;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  animation: pulse 2s infinite;
}

.pulse:hover {
  animation: none;
}

.pulse-white {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: #f0dac7;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(204,169,44, 0.4);
  animation: pulse 2s infinite;
}

.pulse-white:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 40px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
    box-shadow: 0 0 0 0 rgba(204,169,44, 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 40px rgba(204,169,44, 0);
      box-shadow: 0 0 0 40px rgba(204,169,44, 0);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
      box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
}

.currency-flag {
  display: inline-block;
  width: 17px !important;
  height: 11px !important;
  background-size: cover;
}

.btc-flag {
  display: inline-block;
  width: 17px !important;
  height: 17px !important;
  background-size: cover;
}